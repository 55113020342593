import { Typography } from "@mui/material";
import React from "react";
import gify from '../img/giphy.gif'

const HashNotFound = () => {
  return (
    <div>
      <img src={ gify } alt='NOT FOUND'></img>
      <br></br>
      <Typography align="center" variant="h2" color="error">
        Hash Not Found
      </Typography>
    </div>
  );
};

export default HashNotFound;
