import { Typography } from '@mui/material'
import React from 'react'
import {Link} from 'react-router-dom'

const Notfound = () => {
  return (
    <div className="container margin-0-auto text-center my-5">
      <Typography variant="h1">404</Typography>  
      <Link to='/verify'><Typography variant='h6' className="my-2">Return to verify page</Typography></Link>
    </div>
  )
}

export default Notfound