import { React, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import QrReader from 'react-qr-reader'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Link } from 'react-router-dom';

const Scanqr = () => {
    const [result, setResult] = useState("");
    const [open, setopen] = useState(false);
    const [erdia, seterdia] = useState(false);
    const handleScan = data => {
        if (data) {
          setResult(data);
          setopen(true);
        }
      }
      const handleError = (err)=> {
        console.error(err);
        seterdia(true);
      }

      const handleClose = () => {
        setopen(false);
        seterdia(false);
      };
    return (
        <div>
            <Navbar active="verify"></Navbar>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <center>
            <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            className="qrscanner"
            />
            </center>
            
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                Visit location
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {"Visit now - "+result}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>close</Button>
                
                <Button onClick={handleClose} href={result} autoFocus>
                    Go
                </Button>
                </DialogActions>
            </Dialog>
            
            <Dialog
                open={erdia}
                onClose={handleClose}
                aria-labelledby="erdia-dialog-title"
                aria-describedby="erdia-dialog-description"
            >
                <DialogTitle id="erdia-dialog-title">
                Error
                </DialogTitle>
                <DialogContent>
                <DialogContentText id="erdia-dialog-description">
                    Camera Not Found
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>close</Button>
                
                <Button href="../verify" autoFocus>
                    Go back
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Scanqr
