import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import Verify from './pages/Verify';
import Contact from './pages/Contact';
import Verifyhash from './pages/Verifyhash';
import Scanqr from './pages/Scanqr';
import Viewcertificate from './pages/Viewcertificate';
import { useState } from 'react';
import Notfound from './pages/Notfound';


function App() {
  const [hash, setHash] = useState("");
  function updateHash(newhash){
    setHash(newhash);
    console.log("hash = "+hash);
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" exact element={<Home></Home>}/>
          <Route path="/verify" exact element={<Verify></Verify>}/>
          <Route path="/scanqr" exact element={<Scanqr></Scanqr>}/>
          <Route path="/verify/:hash" exact element={<Verifyhash updateHash={updateHash}></Verifyhash>}/>
          <Route path="/contact" exact element={<Contact></Contact>}/>
          <Route path="/viewcertificate" exact element={<Viewcertificate hash={hash}/>}/>
          <Route path="*" element={<Notfound/>}/>
            
        </Routes>
      </div>
    </Router>
  );
}

export default App;
