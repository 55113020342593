import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { SyncLoader } from "react-spinners";
import Globals from "../Globals";
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';


const Viewcertificate = ({hash}) => {

  const myglobal = new Globals();
  // console.log(hash);
  const [certificate, setCertificate] = useState({});
  const [data, setData] = useState({});
  const [Error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    fetch(myglobal.getblockchainapi()+"get/"+hash)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          console.log(myglobal.getblockchainapi()+"get/"+hash);

          setCertificate(result);
          setData(JSON.parse(result.Data));
          console.log(JSON.parse(result.Data));
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);


  if (!isLoaded) {
    return (
      <>
        <div className="loader">
          <SyncLoader speedMultiplier={0.8}></SyncLoader>
        </div>
      </>
    );
  } else {
    if(hash == ""){
      return (<>
          <Navbar active="verify"></Navbar>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div className='container text-center row margin-0-auto'>
            <Typography variant='h2'>Hash Not Found Error !!</Typography>
            <Link to='/verify'><Typography variant='p'>Return to verify page</Typography></Link>
          </div>
      </>);
    }
    else{
      console.log(data)
      var timestamp = new Date(parseInt(certificate.Timestamp));
      console.log(data["SERIAL NO."])
      var certificateurl = "https://netbirdmedia.com/api/verifyCertificate?uid=certipass&hash="+hash+"&name="+certificate.CertifiedTo+"&timestamp="+certificate.IssueTimestamp+"&event="+certificate.Event+"&certifiedby="+certificate.CertifiedBy;
      // var d = JSON.parse(certificate.Data)
      return (
        <>
            <Navbar active="verify"></Navbar>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <h1 className='text-center page-header'>Certificate Found</h1>
            <hr/>
            <div className='container text-center row margin-0-auto'>
            
              <Typography variant='p' className="col-sm-6 my-4">SERIAL NO. - {data["SERIAL NO."]}</Typography>
              <Typography variant='p' className="col-sm-6 my-4">Enrollment Number - {data["Enrollment Number"]}</Typography>
              <Typography variant='p' className="col-sm-6 my-4">Student Name - {data["Student Name"]}</Typography>
              <Typography variant='p' className="col-sm-6 my-4">Course Name - {data["Course Name"]}</Typography>
              
              <Typography variant='p' className="col-sm-6 my-4">Issued on - {timestamp.toLocaleDateString()+" at "+timestamp.getHours()+":"+timestamp.getMinutes()}</Typography>
            </div>
            <div className='text-center'>
              <a className='btn btn-dark' href={certificateurl}>Download Certificate</a>
            </div>
        </>
      )
    }
  }
}

export default Viewcertificate