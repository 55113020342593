import { Button, Grid, Tooltip, Typography, Container } from "@mui/material";
import { React, useEffect, useState } from "react";
import { useParams } from "react-router";
import Navbar from "../components/Navbar";
import HashNotFound from "../components/HashNotFound";
import { SyncLoader } from "react-spinners";
import Globals from "../Globals";
import { Link } from "react-router-dom";

const Verifyhash = ({ updateHash }) => {
  const myglobal = new Globals();
  const [credit, setCredit] = useState(1);
  let { hash } = useParams();
  const [Error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    fetch(
      myglobal.getblockchainapi() +
        "get/" +
        hash
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          // console.log(result[0].credit);
          // setCredit(result[0].credit);
          console.log(result.isEndorsed);
          if(result.isEndorsed == false){
            setCredit(-1);
          }
          if(result.isEndorsed == undefined){
            setCredit(1);
          }
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (!isLoaded) {
    return (
      <>
        <div className="loader">
          <SyncLoader speedMultiplier={0.8}></SyncLoader>
        </div>
      </>
    );
  } else {
    if (credit !== -1) {
      var addCrediturl = myglobal.getApi() + "addcredit.php?hash=" + hash;
      var getCertificateUrl =
        myglobal.getApi() +
        "verifyCertificate/index.php?uid=" +
        myglobal.getApiKey() +
        "&hash=" +
        hash;
        console.log(getCertificateUrl);
      if (credit > 0) {
        updateHash(hash);
      }
      return (
        <div className="conatiner">
          <Navbar active="verify"></Navbar>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <Tooltip title="Hash code">
                  <Button variant="text">
                    <b>Hash ID: </b>
                    {hash}
                  </Button>
                </Tooltip>
                <Tooltip title="Credits">
                  <Button variant="text" color="success">
                    <b>Credits: </b> {credit}
                  </Button>
                </Tooltip>
              </div>
            </div>

            <div className="contact container">
              <div className="row">
                
                <div className="col-sm-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="596.628"
                    height="416"
                    viewBox="0 0 596.628 416"
                    // id="logo-hero"
                    className="col-lg-7 mobile-hide"
                  >
                    <g id="vindikate" transform="translate(-88.16 -269.08)">
                      <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M476.288,685.08H293.651L88.16,269.08H270.8L386.474,503.293Z"
                        fill="#2581f3"
                      />
                      <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M819.2,269.08l-68.651,139H567.91l68.651-139Z"
                        transform="translate(-134.409)"
                        fill="#ff5b29"
                      />
                    </g>
                  </svg>
                </div>

                <div className="col-sm-6 mt-5">
                  <div className="container">
                    <br></br>

                    <h3 align="center" className="verify-heading">
                      VERIFY YOUR CERTIFICATES
                    </h3>
                    <br></br>
                    <br></br>
                    <div className="text-center">
                      <Typography variant="p" color="error">
                        {credit < 1 ? "No credits" : "Ready to verify"}
                      </Typography>
                      <br></br>
                      <br></br>
                      <Tooltip title="Verify Now" className="m-2">
                        {credit < 1 ? (
                          <Button variant="outlined" color="info" hidden>
                            Verify Now
                          </Button>
                        ) : (
                          <Link to={"/viewcertificate"}>
                            <Button variant="outlined" color="info">
                              Verify Now
                            </Button>
                          </Link>
                        )}
                      </Tooltip>
                      <Tooltip title="Buy Credit" className="m-2">
                        {credit < 1 ? (
                          <Button
                            variant="outlined"
                            href={addCrediturl}
                            color="success"
                          >
                            Buy Credit
                          </Button>
                        ) : (
                          <Button variant="outlined" color="success" hidden>
                            Buy Credit
                          </Button>
                        )}
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          <Navbar active="verify"></Navbar>
          <HashNotFound></HashNotFound>
        </>
      );
    }
  }
};

export default Verifyhash;
