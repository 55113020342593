 export default class Globals{
    constructor(){}

    getApi(){
        return "https://winverify.windikate.com/api/";
    }

    getApiKey(){
        return "certipass";
    }

    getblockchainapi(){
        return "http://134.209.152.100:4000/api/";
    }
}