import { React, useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";
import { Link } from "react-router-dom";

// import Verify from "../pages/Verify";

const Hashform = () => {
  const [hash, setHash] = useState("");
  
  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="row">
            <div className="php-email-form">
              <div className="form-group mt-3">
                {/* <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required /> */}
                <input
                  id="hash-input"
                  className="form-control"
                  onChange={(e) => {
                    setHash(e.target.value);
                  }}
                  value={hash}
                  label="Hash Key"
                  variant="outlined"
                  color="success"
                  placeholder="Paste your hash Id"
                />
              </div>
              <div>
                <Link to={"./" + hash}>
                  <button type="submit" id="vbtn"> Verify</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hashform;
